.Luma {
  height: 85vh;
  user-select: none;
  background-image:
    radial-gradient(at 100% 0%, color-mix(in oklab, transparent, var(--highlight-cyan-foreground) 3%) 0, transparent 50%),
    radial-gradient(at 1% 42%, color-mix(in oklab, transparent, var(--highlight-purple-foreground) 8%) 0, transparent 50%),
    radial-gradient(at 20% 50%, color-mix(in oklab, transparent, var(--highlight-cyan-background) 8%) 0, transparent 50%),
    radial-gradient(at 64% 1%, color-mix(in oklab, transparent, var(--highlight-green-background) 8%) 0, transparent 50%);
}

.ChatWrapper {
  flex-grow: 1;
  overflow: auto;
}

.Messages {
  flex-grow: 1;
  padding: --token(--space-32) --token(--space-16) --token(--space-24) --token(--space-16);
  overflow-x: hidden;
  /* overflow: auto; */

  &>ul {
    list-style: none;
    padding: 0;
  }
}

.Jump {
  position: sticky;
  top: calc(--token(--space-32) * -1);
  z-index: 1;
}

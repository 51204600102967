.Container {
  position: relative;
  padding-top: --token(--space-32);
  min-block-size: calc(100vh - 56px - 46px);
  overflow: auto;
  block-size: 100vh;
  block-size: 100dvh;
  padding-block-start: --token(--space-72);
  padding-block-end:
    max(
      --token(--space-56),
      calc(--token(--space-56) + env(safe-area-inset-bottom))
    );
}

.Header {
  background: linear-gradient(to bottom, var(--global-background), var(--vibrancy-background));
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1;
  padding-top:
    max(
      --token(--space-4),
      calc(--token(--space-4) + env(safe-area-inset-top))
    );
  padding-bottom: --token(--space-4);
  min-height: --token(--space-48);
}

.HeaderSection {
  width: 30%;
}

@import '@lualtek/react-components/dist/core/utils/media.css';

.TabContent {
  max-height: var(--max-height);
  min-height: var(--min-height);
  overscroll-behavior: none;
  overflow: auto;
}

.EmptyContainer {
  flex-grow: 1;
}

.Footer {
  position: sticky;
  bottom: 0;
  padding-top: --token(--space-16);
  padding-bottom: calc(env(safe-area-inset-bottom) + --token(--space-8));
  margin: 1px;

  @media (--from-small) {
    padding-bottom: calc(env(safe-area-inset-bottom) + --token(--space-16));
  }
}

.PullToRefresh {
  position: relative;

  &[data-refreshing='true'] {
    pointer-events: none;
    opacity: 50%;
  }
}

.SpinnerContainer {
  top: calc(var(--header-height) - --token(--space-24));
  position: absolute;
  width: 100%;
}

.Spinner {

  /* position: absolute; */

  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); */
}

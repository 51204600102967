.ShellLayout {
  --header-height: --token(--space-72);

  position: relative;
  background-image:
  radial-gradient(at 100% 48%, color-mix(in oklab, var(--highlight-cyan-foreground), transparent 95%) 0, transparent 50%),
  radial-gradient(at 1% 53%, color-mix(in oklab, var(--highlight-purple-foreground), transparent 90%) 0, transparent 50%),
  radial-gradient(at 64% 21%, color-mix(in oklab, var(--highlight-green-background), transparent 95%) 0, transparent 50%);
  background-attachment: fixed;
  z-index: 0;

  &[data-has-app-switch='true'] {
    --header-height: --token(--space-128);
  }
}


.Header {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1;
  background: linear-gradient(to bottom, var(--global-background), var(--vibrancy-background));
  min-height: calc(--token(--space-40) + --token(--space-4));
  padding-top:
    max(
      --token(--space-4),
      env(safe-area-inset-top)
    );
}

.HeaderCenter {
  flex-shrink: 0;
  position: absolute;
  left: 50%;
  translate: -50% 0 0;

  /* flex-grow: 0; */
}

.ApplicationButton:is(:hover, :active) {
  background: none;
}

.Toolbar {
  backdrop-filter: blur(--token(--vibrancy-blur-strong));
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding-bottom:
    max(
      --token(--space-8),
      env(safe-area-inset-bottom)
    );
  background:
 radial-gradient(
    100% 100% at center 150%,
    var(--vibrancy-background-brand),
    var(--vibrancy-background)
  );
}

.Container {
  min-block-size: calc(100vh - --token(--space-56) - 46px);
  overflow: auto;
  block-size: 100vh;
  block-size: 100dvh;
  padding-block-start: var(--header-height);
  padding-block-end: calc(--token(--space-56) + --token(--space-56));
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid transparent;
  }

}

.Luma {
  padding-bottom: --token(--space-8);
  height: calc(100dvh - var(--header-height));
  background-image:
    radial-gradient(at 100% 0%, color-mix(in oklab, transparent, var(--highlight-cyan-foreground) 3%) 0, transparent 50%),
    radial-gradient(at 1% 42%, color-mix(in oklab, transparent, var(--highlight-purple-foreground) 8%) 0, transparent 50%),
    radial-gradient(at 20% 50%, color-mix(in oklab, transparent, var(--highlight-cyan-background) 8%) 0, transparent 50%),
    radial-gradient(at 64% 1%, color-mix(in oklab, transparent, var(--highlight-green-background) 8%) 0, transparent 50%);
}

.ChatWrapper {
  flex-grow: 1;
  overflow: auto;
}

.Messages {
  flex-grow: 1;

  /* overflow: auto; */

  &>ul {
    list-style: none;
    padding: 0;
  }
}


.Jump {
  position: sticky;
  bottom: calc(--token(--space-24) * -1);
  z-index: 1;
  background: var(--vibrancy-background);
}

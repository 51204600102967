/* stylelint-disable selector-pseudo-class-no-unknown, selector-class-pattern */
.Code {
  position: relative;
  display: inline-grid;

  & code {
    background-color: transparent;
  }

  & code[class*='shiki'],
  & pre[class*='shiki'] {
    overflow: auto;
    overscroll-behavior: contain;
    border-radius: --token(--radius-16);
    color: var(--dimmed-6);
    background: none;
    letter-spacing: 0.7px;

    /* stylelint-disable-next-line declaration-no-important */
    background-color: transparent !important;
    font-family: --token(--font-family-mono);
    text-align: left;
    white-space: pre;
    font-size: initial;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
  }

  & *::selection {
    background-color: var(--dimmed-3);
    color: inherit;
    text-shadow: none;
  }

  /* Code blocks */

  & code[class*='shiki'] {
    padding-left: 0;
    display: unset;
  }

  & :not(pre) > code[class*='shiki'],
  & pre[class*='shiki'] {
    background:
    radial-gradient(50% 80% at 6% 106%, oklch(85% 0.11 180deg / 8%) 0%, oklch(85% 0.11 180deg / 0%) 100%),
    radial-gradient(100% 100% at 17% 80%, oklch(75% 0.15 270deg / 10%) 0%, oklch(75% 0.15 270deg / 0%) 100%),
    var(--vibrancy-background-soft);
  }

  & pre[class*='shiki'] {
    margin: 0;
    overflow: auto;
    padding: --token(--space-24);
    max-height: var(--max-height);

    /* Inline code */
    p:has(.Code) & {
      padding: 0.1em;
      white-space: normal;
    }

    @nest [data-code-block-has-toolbar='true'] & {
      padding-top: 0;
      margin-top: calc(--token(--space-32) + --token(--space-4));
    }
  }

  /* Inline code */

  /* & :not(pre) > code[class*='shiki'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  } */
}

/* .Toolbar {
  position: absolute;
  left: 0;
  right: 0;
  padding: --token(--space-8) --token(--space-8) --token(--space-8) --token(--space-16);
  border-radius: --token(--radius-8) --token(--radius-8) 0 0;
  opacity: 0%;
  transition: opacity --token(--duration-100) --token(--easing-entrance);

  @nest .Code:hover & {
    opacity: 100%;
  }
} */

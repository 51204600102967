@layer core {
  html[data-mobile='true'],
  html[data-mobile='true'] body {
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  fieldset {
    padding: 0;
  }


  @media (any-pointer: coarse) {
    *:focus {
      outline: none;
    }
  }
}

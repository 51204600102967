.Navigation {
  padding-bottom: --token(--space-24);
}

.Link {
  --fg: var(--dimmed-6);
  --border: var(--global-outer-contrast-border);

  color: var(--fg);
  position: relative;
  border-radius: --token(--radius-16);
  z-index: 0;
  transition: color 100ms 0 linear;
  box-shadow: 0 0 0 1px var(--border), inset 0 0 0 1px var(--global-inner-contrast-border);
  font-weight: --token(--font-font-weight-semi-bold);
  max-inline-size: --token(--space-56);

  &:not([aria-current='page']):where(:hover, :focus) {
    --fg: var(--global-foreground);

    background: var(--vibrancy-background-soft);
  }

  &[aria-current='page'] {
    --fg: var(--highlight-brand-foreground);
    --border: var(--fg);

    transition: color 100ms 50ms linear;
    background: var(--vibrancy-background-brand);
    color: var(--fg);
  }
}

@import '@lualtek/react-components/dist/core/utils/media.css';

.Container {
  position: relative;
  padding-bottom: --token(--space-88);
  overflow: hidden;

  @media (--from-extra-small) {
    overflow: visible;
  }
}

.Header {
  margin-bottom: --token(--space-80);
}

.Shape {
  inline-size: 900px;
  block-size: 900px;
  background:
    radial-gradient(91.52% 145.89% at 1.82% 100%, oklch(69.59% 0.15 162.48 / 45.8%) 0%, oklch(69.59% 0.15 162.48 / 0%) 100%),
    radial-gradient(50% 79.71% at 100% 100%, oklch(53.8% 0.26 277.38 / 49.2%) 0%, oklch(53.8% 0.26 277.38 / 0%) 100%),
    var(--vibrancy-background-soft);
  position: absolute;
  top: -600px;
  left: -385px;
  pointer-events: none;
  z-index: -1;

  /* stylelint-disable-next-line max-line-length */
  clip-path: path('M165.977 59.422C183.598 19.845 224.699-3.885 267.785.643L651.834 41.01c43.085 4.528 78.354 36.285 87.362 78.661l80.288 377.725c9.007 42.376-10.297 85.733-47.815 107.394L437.241 797.871c-37.519 21.662-84.718 16.701-116.913-12.288L33.351 527.189C1.156 498.2-8.71 451.778 8.91 412.201l157.067-352.78Z');
  mask-image: linear-gradient(to bottom, white 50%, transparent);
}

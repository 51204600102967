.TabWrapper {
  position: relative;
  width: 28rem;
  overflow: hidden;
  border-radius: 24px;
}

.Settings {
  position: absolute;
  top: --token(--space-8);
  right: --token(--space-16);
}

.Weather {
  background: var(--dimmed-1);
  padding-bottom: --token(--space-32);
}

.Icon {
  inline-size: --token(--space-32);
  block-size: --token(--space-32);
  flex-grow: 0;
  flex-shrink: 0;
}

.IconForecast {
  flex-grow: 0;
  inline-size: --token(--space-56);
  flex-shrink: 0;
}

.Forecast {
  overflow: auto;
  max-height: 60dvh;
  padding-bottom: env(safe-area-inset-bottom);
}

.Link {
  color: var(--global-foreground);
  transition: color 100ms 0 linear;
  min-width: 20%;
  padding: --token(--space-16) 0 --token(--space-4);

  &[aria-current='page'] {
    transition: color 100ms 50ms linear;
    color: var(--global-interactive-text);
  }
}

.Decorator {
  transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1);

  .Link:not([aria-current='page']):active & {
    transform: scale(1.2);
  }
}

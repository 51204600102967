@import '@lualtek/react-components/dist/core/utils/media.css';

.ChatPanel {
  overflow: hidden;
  min-width: min(70vw, 700px);
  height: calc(100dvh - --token(--space-16));

  @media (--from-extra-large) {
    min-width: unset;
    height: 100%;
  }

  &>div {
    height: 100%;
    padding-top: 0;
  }
}


div:has(> .ChatPanel) {
  position: sticky;
  top: var(--shell-gap);
  block-size: calc(100vh - var(--shell-gap) * 2);
  block-size: calc(100dvh - var(--shell-gap) * 2);
}

@import '@lualtek/react-components/dist/core/utils/media.css';

.ShellLayout {
  --shell-gap: --token(--space-8);
  --sidebar-size: calc(88px + var(--shell-gap));
  --cards-background: color-mix(in oklab, var(--global-background), transparent 80%);
  --chat-width: 600px;

  display: grid;
  position: relative;
  z-index: 0;
  gap: var(--shell-gap);
  padding: var(--shell-gap);
  grid-template-columns: var(--sidebar-size) 1fr;

  &[data-is-chat-open='true'] {

    @media (--from-extra-large) {
      grid-template-columns: var(--sidebar-size) minmax(0, var(--chat-width)) minmax(0, 1fr);
    }
  }

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    z-index: -1;
    background-image:
      radial-gradient(at 100% 0%, color-mix(in oklab, transparent, var(--highlight-cyan-foreground) 5%) 0, transparent 50%),
      radial-gradient(at 1% 42%, color-mix(in oklab, transparent, var(--highlight-purple-foreground) 15%) 0, transparent 50%),
      radial-gradient(at 20% 50%, color-mix(in oklab, transparent, var(--highlight-cyan-background) 15%) 0, transparent 50%),
      radial-gradient(at 64% 1%, color-mix(in oklab, transparent, var(--highlight-green-background) 15%) 0, transparent 50%);
  }
}

.SkipToContent {
  height: --token(--space-32);
  left: 50%;
  padding: --token(--space-8);
  position: absolute;
  z-index: 2;
  translate: 0 -100% 0;
  transition: translate 0.3s;

  &:focus-visible {
    translate: 0 0 0;
  }
}

.Header {
  background: var(--cards-background);
  overflow: auto;
  height: 100%;

  &>* {
    height: 100%;
    padding-top: 0;
  }
}

.LogoContainer {
  background: var(--dimmed-0);
  position: relative;
  border-bottom: 0;
  border-radius: 24px 24px 0 0;
  background-clip: padding-box;
  margin: 1px;
  outline-offset: -4px;
  padding: --token(--space-16) 0;
  display: grid;
  place-items: center;

  &>* {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: --token(--space-24);
    height: 24px;
    background: inherit;
  }

  &::before {
    left: 0;
    mask-image: radial-gradient(circle at right bottom, transparent 0, transparent 70%, white 71%);
  }

  &::after {
    right: 0;
    mask-image: radial-gradient(circle at left bottom, transparent 0, transparent 70%, white 71%);
  }
}

div:has(> .Header) {
  position: sticky;
  top: var(--shell-gap);
  block-size: calc(100vh - var(--shell-gap) * 2);
  block-size: calc(100dvh - var(--shell-gap) * 2);
}

.Shape {
  inline-size: 900px;
  block-size: 900px;
  background:
    radial-gradient(91.52% 145.89% at 1.82% 100%, oklch(69.59% 0.15 162.48deg / 85.8%) 0%, oklch(69.59% 0.15 162.48deg / 0%) 100%),
    radial-gradient(50% 79.71% at 100% 100%, oklch(53.8% 0.26 277.38deg / 89.2%) 0%, oklch(53.8% 0.26 277.38deg / 0%) 100%),
    var(--vibrancy-background-soft);
  position: fixed;
  top: -518px;
  left: -493px;
  z-index: -1;
  pointer-events: none;

  /* stylelint-disable-next-line max-line-length */
  clip-path: path('M165.977 59.422C183.598 19.845 224.699-3.885 267.785.643L651.834 41.01c43.085 4.528 78.354 36.285 87.362 78.661l80.288 377.725c9.007 42.376-10.297 85.733-47.815 107.394L437.241 797.871c-37.519 21.662-84.718 16.701-116.913-12.288L33.351 527.189C1.156 498.2-8.71 451.778 8.91 412.201l157.067-352.78Z');
  mask-image: linear-gradient(0deg, white 0%, transparent);
}

.Toolbar {
  margin-bottom: --token(--space-48);
  position: sticky;
  z-index: 1;
  top: 0;

  &::before {
    backdrop-filter: blur(--token(--vibrancy-blur-strong));
    content: '';
    position: absolute;
    inset: 1px 1px -60px;
    pointer-events: none;
    border-radius: --token(--radius-24) --token(--radius-24) 0;
    z-index: -1;
    transition: background --token(--duration-200) --token(--easing-standard);
    mask-image: linear-gradient(to bottom, red 50%, transparent);
  }


  &[data-react-is-sticky='true']::before {
    background: color-mix(in oklab, var(--global-background), transparent 70%);
    top: 0;
    border-radius: 0;
  }
}

.View {
  min-block-size: calc(100vh - var(--shell-gap) * 2);
  min-block-size: calc(100dvh - var(--shell-gap) * 2);
  background: var(--cards-background);
  padding-bottom: calc(env(safe-area-inset-bottom) + --token(--space-24));
}

.Balloon {
  --background: --token(--color-brand-60);
  --curly-offset: 14px;

  position: relative;
  border-radius: --token(--radius-16);
  max-width: 90%;
  min-width: 40%;
  background: var(--background);
  color: --token(--color-brand-0);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    background: transparent;
    border-radius: 0 0 0 20px;
    border-left: 6px solid var(--background);
    width: 8px;
    height: 8px;
    left: auto;
    right: var(--curly-offset);
  }

  &[data-chat-balloon-response='true'] {
    align-self: start;
    background: transparent;
    color: unset;
    max-width: 100%;

    &::after {
      display: none;
    }
  }
}

.Datetime {
  position: absolute;
  bottom: calc((--token(--space-2) + --token(--space-16)) * -1);
  opacity: 0%;
  transition: --token(--duration-100) --token(--easing-entrance);
  translate: -4px;

  .Balloon:hover & {
    opacity: 100%;
    translate: 0;
  }
}

.MessageWrapper {
  max-width: 100%;
  overflow: hidden;
}

.TableWrapper {
  overflow-x: auto;
  margin: --token(--space-8) 0;
  border: 1px solid var(--dimmed-2);
}

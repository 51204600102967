.Notification {
  position: relative;
  outline-offset: -8px;

  &[data-notification-new='true']::before {
    content: '';
    z-index: -1;
    position: absolute;
    inset: 0;
    opacity: 60%;
    background: linear-gradient(15deg, var(--highlight-brand-background), transparent 50%);
  }

  & > *:nth-child(1) {
    flex-shrink: 0;
  }

  & img {
    filter: grayscale();
  }

  &[data-notification-new='true'] img {
    filter: unset;
  }
}

.VersionNumber {
  position: absolute;
  top: 50%;
  left: 50%;
  scale: 2;
}

@import '@lualtek/react-components/dist/core/utils/media.css';

.Sidebar {
  flex-grow: 1;
  padding: --token(--space-24) --token(--space-8);

  @media (--from-small) {
    padding: --token(--space-24);
  }
}

.Content {
  height: 100%;
}

@import '@lualtek/react-components/dist/core/utils/media.css';

.AppsList {
  width: 100vw;

  @media (--from-small) {
    max-inline-size: 598px;
  }
}

.Search {
  position: sticky;
}

.List {
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  max-block-size: 400px;
}

.AppListItem {
  border-radius: --token(--radius-24);
  outline-offset: -3px;

  &[disabled] {
    opacity: 50%;
    cursor: default;
  }

  &:not([disabled]):where([data-app-list-item-is-only='false']:where(:hover)) {
    background-color: var(--highlight-primary-background);
  }
}

.Meta {
  color: var(--dimmed-5);
}

@import '@lualtek/react-components/dist/core/utils/media.css';

.AppSwitcher {
  margin: 0 --token(--space-24);
  position: relative;
  overflow: hidden;
  z-index: 0;
  container-type: inline-size;
}

.SelectedBanner {
  background:
  var(--vibrancy-background-mid)
  radial-gradient(
    circle at -50% 60%,
    color-mix(in oklab, transparent, var(--highlight-green-background) 50%) 0%,
    transparent 100%);
}

.Meta {
  display: none;

  @media (--from-extra-large) {
    display: flex;
  }
}

.WeatherWidget {

  @media not (--from-large) {
    display: none;
  }
}

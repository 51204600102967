
.Input {
  position: relative;

  & textarea {
    resize: none;
    overflow: auto;
    padding-bottom: --token(--space-24);
    padding-right: --token(--space-56);
  }
}

.SendButton {
  position: absolute;
  bottom: --token(--space-8);
  right: --token(--space-8);
}

.ReleaseHeader {
  background-image: url('/dot-grid.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
}

.Ripples {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  place-content: center;
}

.Ripple {
  --scale: 1;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  background: --token(--color-support-black);
  opacity: 15%;
  border-radius: 100%;
  width: 276px;
  height: 276px;
  aspect-ratio: 1 / 1;
  box-shadow: inset 0 0 10px black, inset 0 0 10px black;
  border: 1px solid var(--dimmed-4);
  scale: var(--scale);
}

.VersionNumber {
  z-index: 0;
  user-select: none;
}

.FloatingClose {
  position: absolute;
  top: --token(--space-24);
  right: --token(--space-32);
}

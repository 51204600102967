.Breadcrumbs {
  --background: var(--dimmed-1);
  --items-space: --token(--space-8);
  --link-foreground: var(--global-interactive-text);
  --link-background: var(--highlight-brand-background);
  --breadcrumb-height: --token(--space-48);
  --arrow-height: 22px;
}

.Crumb {
  position: relative;
  min-height: --token(--space-32);
  height: var(--breadcrumb-height);

  &:is(a) {
    --background: var(--link-background);
  }

  &:first-child {
    border-radius: --token(--radius-8) 0 0 --token(--radius-8);
  }

  &:last-child {
    border-radius: 0 --token(--radius-8) --token(--radius-8) 0;
  }


  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-radius: 0 --token(--radius-8) --token(--radius-8) 0;
    border-bottom: var(--arrow-height) solid transparent;
    border-left: var(--arrow-height) solid var(--background);
    border-top: var(--arrow-height) solid transparent;
    z-index: 1;
  }

  & + & {
    margin-left: calc((var(--items-space) / 2) * -1);
  }
}

.Content {
  padding-left: --token(--space-16);
  padding-right: --token(--space-8);
  background: var(--background);
  white-space: nowrap;

  & > span {
    display: inline-block;
  }

  .Crumb:is(a) & {
    color: var(--link-foreground);
  }

  .Crumb:first-child & {
    border-radius: --token(--radius-8) 0 0 --token(--radius-8);
  }

  .Crumb + .Crumb & {
    mask:
      url("data:image/svg+xml,%3Csvg width='700' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M700.5 0H0l12.095 11.355a5 5 0 0 1 0 7.29L0 30h700.5V0Z' fill='%23000'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h700v30H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
      center left / cover no-repeat;
    padding-left: calc(var(--items-space) * 5);
    padding-right: calc(var(--items-space) * 2);
  }

  .Crumb:only-child & {
    border-radius: --token(--radius-8) 0 0 --token(--radius-8);
  }

  .Crumb + .Crumb:not(:last-child) & {
    border-radius: 0;
  }
}
